$(document).ready(function () {
    if ($('.store_details').length == 0) {
        return;
    }
    var lat = parseFloat($('#map').data('lat'));
    var lng = parseFloat($('#map').data('lng'));
    var coordinates = {lat, lng};

    var map = new google.maps.Map(document.getElementById('map'), {
        center: coordinates,
        zoom: 10
    });

    var marker = new google.maps.Marker({
        position: coordinates,
        map: map,
        title: 'Hello World!'
    });
})