import { generateQr } from "./global";

$(document).ready(function () {
    let loader = $('.background_loader');
    let page = 1;

    function loadMoreCoupons() {
        loader.show();

        $.ajax({
            url: pagination_coupon,
            method: 'POST',
            data: { page: page },
            dataType: 'json',
            success: function (response) {
                if (response.success && response.coupons.length > 0) {
                    const $couponsContainer = $('#coupons_show');
                    response.coupons.forEach(coupon => {
                        const couponHtml = `
                        <div class="row line_coupon ${coupon.statusClass}">
                            <div class="col-md-9 left">
                                <h3>${coupon.name}</h3>
                                <p>${coupon.store}</p>
                                <div class="limit_code">
                                    <span>Valable jusqu'au <b>${coupon.limit}</b></span>
                                    <span class="code">${coupon.code}</span>
                                </div>
                            </div>
                            <div class="col-md-3 right" style="background-image: url(${coupon.image});background-repeat: no-repeat;background-position: center;border-radius: 0 30px 30px 0;}">
                                <button ${coupon.isDisabled ? 'disabled' : ''} class="btn generate-qr" type="button" data-coupon-id="${coupon.code}">${coupon.status}</button>
                            </div>
                        </div>`;
                        $couponsContainer.append(couponHtml);
                    });

                    if (!response.hasMore) {
                        $('#loadMore').hide();
                    }
                } else {
                    $('#loadMore').hide();
                }
                loader.hide();
                generateQr(get_url_qr_code);
            },
            error: function () {
                alert('Une erreur est survenue. Veuillez réessayer.');
            }
        });
    }

    $('#loadMore').on('click', function () {
        page++;
        loadMoreCoupons();
    });
});