import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
$(document).ready(function(){
    if ($('.product-accessories').length > 0) {
        new Splide('.product-accessories', {
            type: 'loop',
            perPage: 4,
            autoHeight: true,
            pagination: false,
            gap: '35px',
            breakpoints: {
                420: {
                    perPage: 1,
                    arrows: false,
                    fixedWidth : '220px',
                    gap: '20px',
                    padding: {
                        right: '20%'
                    }
                },
                768: {
                    perPage: 2,
                    arrows: false,
                    fixedWidth : '220px',
                    gap: '20px',
                    padding: {
                        right: '20%'
                    }
                },
            }
        }).mount();
    }
});